import React, { useRef, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import _ from 'lodash'
import {
  Cell,
  CellItem,
  CellList,
  ExpandLinkedCell,
  ExpandLinkedCellWrapper
} from './styled'
import ExpandedRecords from './ExpandedRecords'

function DBTableCell (props) {
  const {
    cell,
    index,
    handleClick
  } = props
  const { getCellProps, value, column: { id, Header, metaData } } = cell

  const [expandLinkedRecords, setExpandLinkedRecords] = useState(false)
  const cellRef = useRef()

  const getCellValue = (value) => {
    const isObjectArray = Array.isArray(value) && value.some(x => x?.value)
    if (isObjectArray) {
      const { joinedTo } = metaData || {}

      return (
        <>
          <CellList className='linked-records'>
            {value.map((data, index) => {
              if (!data) return null
              return (
                <CellItem
                  key={data?.id + index}
                  onClick={() => handleClick(data, joinedTo)}
                >
                  {data?.value}
                </CellItem>
              )
            })}
          </CellList>
          <ExpandLinkedCellWrapper
            data-testid='expand-linked-records'
            onClick={() => setExpandLinkedRecords(true)}
          >
            <ExpandLinkedCell />
          </ExpandLinkedCellWrapper>
        </>
      )
    }

    const isArray = Array.isArray(value)
    if (isArray) {
      return value
        .filter(Boolean)
        .map(getCellValue)
        .join(', ')
    }

    const isBoolean = typeof value === 'boolean'
    if (isBoolean) return value.toString()

    const isObject = _.isObject(value)
    if (isObject) return JSON.stringify(value)

    const dateFormats = ['YYYY-MM-DD']
    const isDate = moment(value, dateFormats, true).isValid()
    if (isDate) {
      return moment(value).format('MM/DD/YYYY')
    }

    const dateTimeFormats = ['YYYY-MM-DDTHH:mm:ss.SSSZ']
    const isDateTime = moment(value, dateTimeFormats, true).isValid()
    if (isDateTime) {
      return moment(value).format('MM/DD/YYYY hh:mm A')
    }

    return value
  }

  const isRowSelection = id === 'selection'
  const isPersonalInfo = [
    'email', 'name', 'id', 'preferredName'
  ].includes(Header)

  return (
    <td
      key={index}
      {...getCellProps()}
      className={classNames(
        'bodyCell',
        { 'fs-exclude': isPersonalInfo },
        { 'edit-cell': index === 1 }
      )}
    >
      {expandLinkedRecords && (
        <ExpandedRecords
          setExpandLinkedRecords={setExpandLinkedRecords}
          metaData={metaData}
          cell={cell}
          cellRef={cellRef}
          handleClick={handleClick}
        />
      )}
      {isRowSelection && (
        <span {...cell.getCellProps()}>{cell.render('Cell')}</span>
      )}
      <Cell ref={cellRef}>{getCellValue(value)}</Cell>
    </td>
  )
}

export default DBTableCell
