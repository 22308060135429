import React, { useState, useEffect, useMemo } from 'react'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns
} from 'react-table'
import api from '../../api'
import './table.css'
import CaretUpSelected from '../../assets/icons/caret-up-selected.svg'
import CaretUpUnselected from '../../assets/icons/caret-up-unselected.svg'
import ExpandIcon from '../../assets/icons/arrows-angle-expand.svg'
import DBTableCell from './DBTableCell'
import ExpandedItem from '../DatabaseViewer/ExpandedItem'
import {
  ResizeHandle, ResizeHandleContainer, SortContainer, TableComponent
} from './styled'
import useColumnResizeObserver from '../../Hooks/useColumnResizeObserver'

export default function DBTable ({
  columns,
  data,
  handleSort,
  sort,
  onRightClickHandler,
  onMultiSelectHandler,
  onEditClickHandler,
  updateCurrentView,
  currentView,
  tableName,
  canUserEditDatabase
}) {
  const [isLoadingItemDetails, setIsLoadingItemDetails] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedItemTable, setSelectedItemTable] = useState(null)

  const handleClickCellItem = async (data, tableName) => {
    setIsLoadingItemDetails(true)
    setSelectedItemTable(tableName)
    const columnMetaData = await api.getDataTableMetadata({ tableName })
    const rowDetails = await api.getTableFilter({
      tableName,
      filters: {
        id: {
          is: data?.id
        },
        rules: [
          {
            field: 'id',
            operator: 'is',
            value: data?.id
          }
        ]
      }
    })
    setSelectedItem({ rowDetails, columnMetaData })
    setIsLoadingItemDetails(false)
  }

  const handleCloseCellItem = () => {
    setSelectedItem(null)
    setSelectedItemTable(null)
  }

  const defaultColumn = useMemo(
    () => ({
      minWidth: 60,
      width: 200
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    rows,
    ...rest
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: sort
      },
      manualPagination: true,
      manualSortBy: true
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          minWidth: 45,
          width: 45,
          maxWidth: 45,
          disableResizing: true,
          Header: () => null,
          Cell: ({ row }) => {
            return (
              <img
                data-testid='edit-record'
                alt='Edit record'
                src={ExpandIcon}
                onClick={() => onEditClickHandler(row.original)}
              />
            )
          }
        },
        ...columns
      ])
    }
  )
  const { state: { sortBy }, state } = rest

  useColumnResizeObserver(state, async (columnId, columnSize) => {
    if (currentView.label === 'Default') return

    const { id, label, value, columnWidths = {}, ...rest } = currentView
    const newColumnWidths = { ...columnWidths, [columnId]: columnSize }
    updateCurrentView('columnWidths', newColumnWidths)

    await api.updateTableView({
      tableName,
      id,
      view: {
        ...rest,
        columnWidths: { ...newColumnWidths }
      }
    })
  })

  useEffect(() => {
    handleSort(sortBy)
    // eslint-disable-next-line
  }, [sortBy])

  useEffect(() => {
    onMultiSelectHandler && onMultiSelectHandler(selectedFlatRows)
    // eslint-disable-next-line
  }, [selectedFlatRows.length])

  const tableHeader = () => {
    return (
      <thead className='tableHeader'>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='headerRow'>
            {headerGroup.headers.map((column, index) => {
              const isActive = column.prop === sortBy[0]?.id
              return (
                <th
                  style={{
                    left: index === 2 && (data.length ? 345 : 292)
                  }}
                  className='headerColumn tooltip-container'
                  {...column.getHeaderProps()}
                >
                  {column.metaData?.description && (
                    <pre id='DBTable-tooltip'>
                      {column.metaData?.description}
                    </pre>
                  )}
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: '100%', display: 'flex' }}
                  >
                    <span>{column.render('Header')}</span>
                    {column.id !== 'selection' && (
                      <SortContainer>
                        <img
                          src={isActive && sortBy[0]?.desc === false
                            ? CaretUpSelected
                            : CaretUpUnselected}
                          alt='disabled dropdown'
                        />
                        <img
                          style={{ transform: 'rotate(180deg)' }}
                          src={isActive && sortBy[0]?.desc
                            ? CaretUpSelected
                            : CaretUpUnselected}
                          alt='disabled dropdown'
                        />
                      </SortContainer>
                    )}
                  </div>
                  {column.canResize && (
                    <ResizeHandleContainer
                      {...column.getResizerProps()}
                      data-testid='column-resizer'
                    >
                      <ResizeHandle isResizing={column.isResizing} />
                    </ResizeHandleContainer>
                  )}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
    )
  }

  const tableBody = () => {
    return (
      <tbody {...getTableBodyProps()} className='tableBody'>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              key={i}
              className='tableBodyRow'
              onContextMenu={(e) => {
                e.preventDefault()
                onRightClickHandler(e, row)
              }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell, j) => {
                return (
                  <DBTableCell
                    key={j}
                    cell={cell}
                    index={j}
                    handleClick={handleClickCellItem}
                  />
                )
              })}
            </tr>
          )
        })}

        {!data.length && (<tr className='tableBodyRow'>
          <td className='bodyCell' colSpan={columns.length}>
              No results to be shown.
          </td>
        </tr>)}
      </tbody>
    )
  }

  return (
    <>
      {selectedItemTable && <ExpandedItem
        handleClose={handleCloseCellItem}
        isLoading={isLoadingItemDetails}
        selectedItem={selectedItem}
        tableName={selectedItemTable}
        canUserEditDatabase={canUserEditDatabase}
      />}
      <TableComponent
        isResizingColumn={state.columnResizing?.isResizingColumn}
        {...getTableProps()}
        className={`edit-and-delete-table table-responsive`}>
        {tableHeader()}
        {tableBody()}
      </TableComponent>
    </>
  )
}
